<template>
  <div class="osahan-signup login-page">
    <video loop="" autoplay="" muted="" id="vid">
      <source src="img/bg.mp4" type="video/mp4" />
      <source src="img/bg.mp4" type="video/ogg" />
      Your browser does not support the video tag.
    </video>
    <div
      style="margin-top: -68px"
      class="
        d-flex
        align-items-center
        justify-content-center
        flex-column
        vh-100
      "
    >
      <div class="px-5 col-md-6 ml-auto">
        <div class="px-5 col-10 mx-auto">
          <h2>Forgot password</h2>
          <p>
            Enter your email address below and we'll send you an email with
            instructions on how to change your password
          </p>
          <form class="mt-5 mb-4">
            <div class="form-group">
              <label for="exampleInputEmail1">Email</label>
              <input
                v-model="email"
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <a v-on:click="sendPass" class="btn btn-primary btn-lg btn-block"
              >Send</a
            >
          </form>
        </div>
        <div class="new-acc d-flex align-items-center justify-content-center">
          <a href="login">
            <p class="text-center m-0">Already an account? Sign in</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: null,
    };
  },
  methods: {
    sendPass() {
      let url = `${this.$store.state.baseURL}/api/forgot-password?email=${this.email}`;
      let request = new Request(url, { method: "POST" });
      fetch(request)
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
        })
        .then((r) => {
          if (r.response) {
            window.localStorage.setItem(
              "temp",
              `{"reset_token":"${r.data.reset_token}"}`
            );
            this.$router.push("success_save");
          } else {
            if (r.message) alert(r.message);
            if (r.messages) alert(r.messages);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>